$(document).ready(function(){
    $(".owl-carousel").owlCarousel({
        navRewind: false,
        lazyLoad: true,
        center:true,
        items:3,
        loop: true,
        autoWidth:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        animateOut: 'fadeOut',
        nav    : true,
        navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        responsive:{
            600:{
                items:4
            }
        },
        dots: true

    });

    if ($('.js-add-personalize').length) {
        $('.js-add-personalize').on('click', function(e) {
            e.preventDefault();
            var dataLayer = $(this).data('personalize');
            $('#item-'+dataLayer).removeClass('hide');
            $(this).addClass('hide');
        })
    }

    if ($('.js-open-gallery').length) {
        $('.js-open-gallery').summitLightbox();
    }
});